const PLATFORM_LIST = [
	{ label: 'A2P Core / SMS Gateway', value: 'A2P Core / SMS Gateway' },
	{
		label: 'Accountable Form Monitoring System (AFMS)',
		value: 'Accountable Form Monitoring System (AFMS)'
	},
	{ label: 'Admintool', value: 'Admintool' },
	{
		label: 'Adobe Experience Manager (AEM)',
		value: 'Adobe Experience Manager (AEM)'
	},
	{ label: 'Adobe Target', value: 'Adobe Target' },
	{
		label: 'Agent Sales and After-sales Platform (ASAP)',
		value: 'Agent Sales and After-sales Platform (ASAP)'
	},
	{ label: 'Alexandria', value: 'Alexandria' },
	{
		label: 'AMAX (Prepaid Reloading Platform)',
		value: 'AMAX (Prepaid Reloading Platform)'
	},
	{
		label: 'AMAX 2.0 (Prepaid Reloading Platform)',
		value: 'NAMAX'
	},
	{ label: 'AMAX CE', value: 'AMAX CE' },
	{ label: 'AMAX CE LTP', value: 'AMAX CE LTP' },
	{ label: 'Amazon Connect', value: 'Amazon Connect' },
	{ label: 'Amdocs PCRF', value: 'Amdocs PCRF' },
	{ label: 'Anaplan', value: 'Anaplan' },
	{
		label: 'Ansible (Infrastructure Automation Platform)',
		value: 'Ansible (Infrastructure Automation Platform)'
	},
	{
		label: 'API Management Platform (Wakanda/API Gateway)',
		value: 'API Management Platform (Wakanda/API Gateway)'
	},
	{ label: 'API Monetization', value: 'API Monetization' },
	{ label: 'AppDynamics', value: 'AppDynamics' },
	{ label: 'Appian', value: 'Appian' },
	{ label: 'Application Middleware', value: 'Application Middleware' },
	{
		label: 'Application Processing System (APS)',
		value: 'Application Processing System (APS)'
	},
	{ label: 'Appsheet', value: 'Appsheet' },
	{
		label: 'Aptitude Revenue Recognition Engine (ARRE)',
		value: 'Aptitude Revenue Recognition Engine (ARRE)'
	},
	{ label: 'Athena', value: 'Athena' },
	{ label: 'Audit Analytics Tool', value: 'Audit Analytics Tool' },
	{
		label: 'Audit Reconciliation and Monitoring System (ARMS)',
		value: 'Audit Reconciliation and Monitoring System (ARMS)'
	},
	{ label: 'Audit Reports Consolidator', value: 'Audit Reports Consolidator' },
	{
		label: 'Aurora (Cloud Orchestration Platform)',
		value: 'Aurora (Cloud Orchestration Platform)'
	},
	{
		label: 'Automatic Roamer Detection System (ARDS)',
		value: 'Automatic Roamer Detection System (ARDS)'
	},
	{ label: 'AVAP', value: 'AVAP' },
	{ label: 'Avaya', value: 'Avaya' },
	{ label: 'AWS API', value: 'AWS API' },
	{
		label: 'AWS Cost Optimization Platform',
		value: 'AWS Cost Optimization Platform'
	},
	{
		label: 'Bank Reloading Platform (PNS/Aesop)',
		value: 'Bank Reloading Platform (PNS/Aesop)'
	},
	{ label: 'Bayantel', value: 'Bayantel' },
	{
		label: 'Bayantel Fraud Management System',
		value: 'Bayantel Fraud Management System'
	},
	{
		label: 'Bill Delivery Management (Levi)',
		value: 'Bill Delivery Management (Levi)'
	},
	{ label: 'Bill Proof Online (Adele)', value: 'Bill Proof Online (Adele)' },
	{ label: 'Bill QA', value: 'Bill QA' },
	{
		label: 'Bills Online Repository for Wireline Services (Amidala)',
		value: 'Bills Online Repository for Wireline Services (Amidala)'
	},
	{ label: 'Black Diamond (Diamond)', value: 'Black Diamond (Diamond)' },
	{ label: 'Blackline', value: 'Blackline' },
	{ label: 'BMC Remedy Force', value: 'BMC Remedy Force' },
	{ label: 'BTSS/IPN & WSMS', value: 'BTSS/IPN & WSMS' },
	{
		label: 'Call Circle Promo Platform (Checkers)',
		value: 'Call Circle Promo Platform (Checkers)'
	},
	{
		label: 'Campaign Management System (Pontis)',
		value: 'Campaign Management System (Pontis)'
	},
	{
		label: 'Cards Management System (Ambition)',
		value: 'Cards Management System (Ambition)'
	},
	{ label: 'CCP (Charging Proxy)', value: 'CCP (Charging Proxy)' },
	{
		label: 'CDS (Code Distribution System)',
		value: 'CDS (Code Distribution System)'
	},
	{
		label: 'Central Receipting Integrated System (CERIS)',
		value: 'Central Receipting Integrated System (CERIS)'
	},
	{
		label: 'Centralized Authentication Service (CAS/Sparco)',
		value: 'Centralized Authentication Service (CAS/Sparco)'
	},
	{
		label: 'Centralized Negative Database (CNDB)',
		value: 'Centralized Negative Database (CNDB)'
	},
	{ label: 'Charging Gateway', value: 'Charging Gateway' },
	{
		label: 'Chia - Mobile MVNO Service (ABSC & Cherry)',
		value: 'Chia - Mobile MVNO Service (ABSC & Cherry)'
	},
	{ label: 'Ciena MCP', value: 'Ciena MCP' },
	{ label: 'Cisco ACI', value: 'Cisco ACI' },
	{
		label: 'Cloud Document Management System (CDMS)',
		value: 'Cloud Document Management System (CDMS)'
	},
	{ label: 'CNMaestro', value: 'CNMaestro' },
	{ label: 'Cognos', value: 'Cognos' },
	{
		label: 'Common Experience Services (CX Services)',
		value: 'Common Experience Services (CX Services)'
	},
	{ label: 'Confluence', value: 'Confluence' },
	{
		label: 'Contact Policy & Notifi cation Management (Raven)',
		value: 'Contact Policy & Notifi cation Management (Raven)'
	},
	{
		label: 'Content Delivery Platform (Bagel OTTI/OTTB)',
		value: 'Content Delivery Platform (Bagel OTTI/OTTB)'
	},
	{
		label: 'Content Delivery Platform (Ripley)',
		value: 'Content Delivery Platform (Ripley)'
	},
	{
		label: 'Content Provisioning for Wireline (Bourne PPS/SPS)',
		value: 'Content Provisioning for Wireline (Bourne PPS/SPS)'
	},
	{
		label: 'Control and Monitoring Execution Tool (COMET)',
		value: 'Control and Monitoring Execution Tool (COMET)'
	},
	{ label: 'Conversion Server (DAC)', value: 'Conversion Server (DAC)' },
	{
		label: 'Corporate Sales Management System',
		value: 'Corporate Sales Management System'
	},
	{
		label: 'Corporate Solutions Billing Mediation (CSBM)',
		value: 'Corporate Solutions Billing Mediation (CSBM)'
	},
	{
		label: 'Create Your Own Product (CYO)',
		value: 'Create Your Own Product (CYO)'
	},
	{
		label: 'Credit Info Management System',
		value: 'Credit Info Management System'
	},
	{
		label: 'Credit Information Management System (Martingale)',
		value: 'Credit Information Management System (Martingale)'
	},
	{
		label: 'Credit Risk Compliance Tool',
		value: 'Credit Risk Compliance Tool'
	},
	{
		label: 'Customer Decision Hub (CDH)',
		value: 'Customer Decision Hub (CDH)'
	},
	{
		label: 'Customer Identity and Access Management (CIAM)',
		value: 'Customer Identity and Access Management (CIAM)'
	},
	{ label: 'Customer Support Tool', value: 'Customer Support Tool' },
	{ label: 'Cycognito', value: 'Cycognito' },
	{
		label:
			'Data Anonymization and De-identifi cation/Encryption Tool (DADE Tool)',
		value:
			'Data Anonymization and De-identifi cation/Encryption Tool (DADE Tool)'
	},
	{ label: 'Data Discovery Tool', value: 'Data Discovery Tool' },
	{ label: 'Data Handling Agent (DHA)', value: 'Data Handling Agent (DHA)' },
	{ label: 'Data Warehouse (Optimus)', value: 'Data Warehouse (Optimus)' },
	{ label: 'Datami (Sponsored Access)', value: 'Datami (Sponsored Access)' },
	{ label: 'Detour', value: 'Detour' },
	{ label: 'Device Management (Novo)', value: 'Device Management (Novo)' },
	{ label: 'Diameter Firewall', value: 'Diameter Firewall' },
	{
		label: 'Digital Adoption Platform (WalkMe)',
		value: 'Digital Adoption Platform (WalkMe)'
	},
	{
		label: 'Digital Brand Platform (DBP)',
		value: 'Digital Brand Platform (DBP)'
	},
	{
		label: 'Digital Network Operator',
		value: 'Digital Network Operator'
	},
	{
		label: 'Disney Provisioning Platform (Magic)',
		value: 'Disney Provisioning Platform (Magic)'
	},
	{
		label: 'Distributor Management System (IOTA)',
		value: 'Distributor Management System (IOTA)'
	},
	{
		label: 'Document Management as a Service (DMaaS)',
		value: 'Document Management as a Service (DMaaS)'
	},
	{ label: 'Domestic SIP Firewall', value: 'Domestic SIP Firewall' },
	{ label: 'Domestic SS7 Firewall', value: 'Domestic SS7 Firewall' },
	{ label: 'Download (VAS)', value: 'Download (VAS)' },
	{ label: 'dSP Back office', value: 'dSP Back office' },
	{
		label: 'Dynamic SIM Allocation (DSA)',
		value: 'Dynamic SIM Allocation (DSA)'
	},
	{
		label: 'E-Bid Cloud Subscription (Loki)',
		value: 'E-Bid Cloud Subscription (Loki)'
	},
	{ label: 'E-Sourcing', value: 'E-Sourcing' },
	{ label: 'E-Tendering', value: 'E-Tendering' },
	{
		label: 'EasySurf/GoSurf (WiFi portal)',
		value: 'EasySurf/GoSurf (WiFi portal)'
	},
	{
		label: 'eBill Delivery Assurance (EDA)',
		value: 'eBill Delivery Assurance (EDA)'
	},
	{
		label: 'eBill Delivery Platform (uRLA)',
		value: 'eBill Delivery Platform (uRLA)'
	},
	{ label: 'eCWT', value: 'eCWT' },
	{ label: 'Elastic Path', value: 'Elastic Path' },
	{
		label: 'EDS Data Services', value: 'EDS Data Services'
	},
	{
		label: 'Email Management System (OTRS)',
		value: 'Email Management System (OTRS)'
	},
	{
		label: 'Employee Asset Management (DASH)',
		value: 'Employee Asset Management (DASH)'
	},
	{
		label: 'Enterprise Identity & Access Management (EIAM)',
		value: 'Enterprise Identity & Access Management (EIAM)'
	},
	{
		label: 'Enterprise Service Bus (ESB)',
		value: 'Enterprise Service Bus (ESB)'
	},
	{
		label: 'EoR (Electronic Offi cial Receipt)',
		value: 'EoR (Electronic Offi cial Receipt)'
	},
	{
		label: 'eRFC (Request for Condemnation)',
		value: 'eRFC (Request for Condemnation)'
	},
	{ label: 'ESIM', value: 'ESIM' },
	{ label: 'EVA App', value: 'EVA App' },
	{
		label: 'Evolved Charging Suite (ECS)',
		value: 'Evolved Charging Suite (ECS)'
	},
	{ label: 'Facebook Conversion API', value: 'Facebook Conversion API' },
	{ label: 'Facial Recognition', value: 'Facial Recognition' },
	{ label: 'FB Workplace', value: 'FB Workplace' },
	{
		label: 'FBM (Facebook Messenger Bot for Broadband) Chatbot',
		value: 'FBM (Facebook Messenger Bot for Broadband) Chatbot'
	},
	{ label: 'File Reformatter/TRX', value: 'File Reformatter/TRX' },
	{
		label: 'File Repository & Reports Processing (FRRP)',
		value: 'File Repository & Reports Processing (FRRP)'
	},
	{ label: 'FireEye ETP', value: 'FireEye ETP' },
	{
		label: 'FireEye Network Threat Prevention Platform',
		value: 'FireEye Network Threat Prevention Platform'
	},
	{
		label: 'Firefly (KMS for Wireline Technician and ID Verification)',
		value: 'Firefly (KMS for Wireline Technician and ID Verification)'
	},
	{
		label: 'Forescout Network Access Control',
		value: 'Forescout Network Access Control'
	},
	{ label: 'Foxbat System (Mediation)', value: 'Foxbat System (Mediation)' },
	{
		label: 'Fraud Management System (FMS)',
		value: 'Fraud Management System (FMS)'
	},
	{ label: 'FreeFB (Specter)', value: 'FreeFB (Specter)' },
	{ label: 'Freight Forwarding', value: 'Freight Forwarding' },
	{ label: 'Frodo', value: 'Frodo' },
	{ label: 'G Access', value: 'G Access' },
	{ label: 'G Check', value: 'G Check' },
	{ label: 'G Trace', value: 'G Trace' },
	{ label: 'GCash Up', value: 'GCash Up' },
	{ label: 'GCP B2B Demand Sizing', value: 'GCP B2B Demand Sizing' },
	{ label: 'GEOTDR', value: 'GEOTDR' },
	{
		label: 'Globe Ambassador App (Magnolia)',
		value: 'Globe Ambassador App (Magnolia)'
	},
	{ label: 'Globe at Home App (BB App)', value: 'Globe at Home App (BB App)' },
	{ label: 'Globe Calltree App', value: 'Globe Calltree App' },
	{
		label: 'Globe Emergency Service (GES)',
		value: 'Globe Emergency Service (GES)'
	},
	{
		label: 'Globe Enterprise Mapping Application (GEMA)',
		value: 'Globe Enterprise Mapping Application (GEMA)'
	},
	{ label: 'Globe Online', value: 'Globe Online' },
	{ label: 'Globe Spotify', value: 'Globe Spotify' },
	{ label: 'Globe Switch', value: 'Globe Switch' },
	{ label: 'Globe Website - Community', value: 'Globe Website - Community' },
	{ label: 'Globe Website (BRIE)', value: 'Globe Website (BRIE)' },
	{ label: 'Globelabs Platform', value: 'Globelabs Platform' },
	{ label: 'GlobeOne', value: 'GlobeOne' },
	{ label: 'GlobeOne B2B', value: 'GlobeOne B2B' },
	{ label: 'GMovies/Upstream', value: 'GMovies/Upstream' },
	{ label: 'GoAral App', value: 'GoAral App' },
	{ label: 'Gomo (Dawn) Chatbot', value: 'Gomo (Dawn) Chatbot' },
	{ label: 'Google RCS', value: 'Google RCS' },
	{
		label: 'Google Traffic Application Function (GTAF)',
		value: 'Google Traffic Application Function (GTAF)'
	},
	{ label: 'Google Workspace', value: 'Google Workspace' },
	{
		label: 'GoWiFi Auto/Free (WiFi portal)',
		value: 'GoWiFi Auto/Free (WiFi portal)'
	},
	{ label: 'Grafana', value: 'Grafana' },
	{
		label: 'GrayMatic Vista Video Analytics',
		value: 'GrayMatic Vista Video Analytics'
	},
	{ label: 'Groam (Fun2)', value: 'Groam (Fun2)' },
	{ label: 'Gservices', value: 'Gservices' },
	{ label: 'Guavus Freud', value: 'Guavus Freud' },
	{ label: 'H2H (Host-to-Host)', value: 'H2H (Host-to-Host)' },
	{ label: 'HP MD Activation', value: 'HP MD Activation' },
	{
		label: 'HP Metering Tool (Backup as a Service)',
		value: 'HP Metering Tool (Backup as a Service)'
	},
	{ label: 'HR Portal', value: 'HR Portal' },
	{
		label: 'Hybrid Integration Platform (HIP)',
		value: 'Hybrid Integration Platform (HIP)'
	},
	{
		label: 'ICCBS Debt Management System',
		value: 'ICCBS Debt Management System'
	},
	{ label: 'ICD NTG Ticketing Tool', value: 'ICD NTG Ticketing Tool' },
	{ label: 'ICON', value: 'ICON' },
	{ label: 'ICONIC', value: 'ICONIC' },
	{ label: 'ICONx', value: 'ICONx' },
	{
		label: 'Identity and Access Management (Sailpoint)',
		value: 'Identity and Access Management (Sailpoint)'
	},
	{ label: 'Identity Services Engine', value: 'Identity Services Engine' },
	{
		label: 'Integrated Customer Care and Billing System (ICCBS)',
		value: 'Integrated Customer Care and Billing System (ICCBS)'
	},
	{
		label: 'Integrated Customer System (ICS)',
		value: 'Integrated Customer System (ICS)'
	},
	{
		label: 'Integrated Receipting and Inventory System for Broadband (IRIS)',
		value: 'Integrated Receipting and Inventory System for Broadband (IRIS)'
	},
	{ label: 'Interactive Bill (iBill)', value: 'Interactive Bill (iBill)' },
	{
		label:
			'Interdomain/Tribe Delivery Governance Google Cloud Platform (IDG GCP)',
		value:
			'Interdomain/Tribe Delivery Governance Google Cloud Platform (IDG GCP)'
	},
	{
		label: 'Interim Service Order Management (ISOM)',
		value: 'Interim Service Order Management (ISOM)'
	},
	{ label: 'Internal Wifi Services', value: 'Internal Wifi Services' },
	{ label: 'International SIP Firewall', value: 'International SIP Firewall' },
	{
		label: 'International SMS Anti Spam Firewall',
		value: 'International SMS Anti Spam Firewall'
	},
	{ label: 'International SS7 Firewall', value: 'International SS7 Firewall' },
	{ label: 'ISG Centralized Logger', value: 'ISG Centralized Logger' },
	{ label: 'iSpeak', value: 'iSpeak' },
	{
		label: 'Inquiro Env', value: 'Inquiro Env'
	},
	{
		label: 'Ivanti Cloud Security Appliance',
		value: 'Ivanti Cloud Security Appliance'
	},
	{ label: 'IVES', value: 'IVES' },
	{
		label: 'IVR Whitelisting for Postpaid (Pony)',
		value: 'IVR Whitelisting for Postpaid (Pony)'
	},
	{ label: 'Jenkins', value: 'Jenkins' },
	{ label: 'Jira', value: 'Jira' },
	{ label: 'Kafka Bridging Server', value: 'Kafka Bridging Server' },
	{
		label: 'KIA (Knowledge Information Assistant) Chatbot',
		value: 'KIA (Knowledge Information Assistant) Chatbot'
	},
	{ label: 'Kibana', value: 'Kibana' },
	{
		label: 'Knowledge Management System (KMS)',
		value: 'Knowledge Management System (KMS)'
	},
	{
		label: 'KonekTayoWiFi (WiFi portal)',
		value: 'KonekTayoWiFi (WiFi portal)'
	},
	{ label: 'Kudos', value: 'Kudos' },
	{
		label: 'Leads Management Platform (LMX)',
		value: 'Leads Management Platform (LMX)'
	},
	{
		label: 'Legal Contract Routing (LCR)',
		value: 'Legal Contract Routing (LCR)'
	},
	{ label: 'Lex Chatbot', value: 'Lex Chatbot' },
	{
		label: 'Lex Enterprise Chatbot Solution',
		value: 'Lex Enterprise Chatbot Solution'
	},
	{ label: 'Loans Management Platform', value: 'Loans Management Platform' },
	{ label: 'LotusFlare DNO (ICT)', value: 'LotusFlare DNO ICT' },
	{
		label: 'Loyalty Inventory Management System (LIMS)',
		value: 'Loyalty Inventory Management System (LIMS)'
	},
	{
		label: 'Loyalty Management Platform (NLP)',
		value: 'Loyalty Management Platform (NLP)'
	},
	{ label: 'LTE@Home', value: 'LTE@Home' },
	{ label: 'Manifold', value: 'Manifold' },
	{
		label: 'Material Request Form (MRF)',
		value: 'Material Request Form (MRF)'
	},
	{ label: 'Mega Hopex', value: 'Mega Hopex' },
	{
		label: 'Merch Requisition Form Web Application',
		value: 'Merch Requisition Form Web Application'
	},
	{ label: 'Microtel POS (MTPOS)', value: 'Microtel POS (MTPOS)' },
	{
		label: 'Mobile Banking Encryption System (MBES)',
		value: 'Mobile Banking Encryption System (MBES)'
	},
	{ label: 'Mobile Number Portability', value: 'Mobile Number Portability' },
	{
		label: 'Mobile Service Quality Agent (MSQA)',
		value: 'Mobile Service Quality Agent (MSQA)'
	},
	{ label: 'Modem Configuration Tool', value: 'Modem Configuration Tool' },
	{
		label: 'MyAccounts for Corporate (Gourmet)',
		value: 'MyAccounts for Corporate (Gourmet)'
	},
	{ label: 'MyBSS', value: 'MyBSS' },
	{
		label: 'MyBSS CatalogeOne DigitalOne (C1D1)',
		value: 'MyBSS CatalogeOne DigitalOne (C1D1)'
	},
	{
		label: 'MyBSS Customer Interaction Manager',
		value: 'MyBSS Customer Interaction Manager'
	},
	{
		label: 'MyBSS Customer Relationship Management (CRM)',
		value: 'MyBSS Customer Relationship Management (CRM)'
	},
	{ label: 'MyBSS e-billing', value: 'MyBSS e-billing' },
	{ label: 'MyBSS e-commerce', value: 'MyBSS e-commerce' },
	{ label: 'MyBSS e-service', value: 'MyBSS e-service' },
	{ label: 'MyBSS Marketing Connectors', value: 'MyBSS Marketing Connectors' },
	{
		label: 'MyBSS Mass Change Orchestrator',
		value: 'MyBSS Mass Change Orchestrator'
	},
	{ label: 'MyBSS Money Map', value: 'MyBSS Money Map' },
	{ label: 'MyBSS Process Manager', value: 'MyBSS Process Manager' },
	{
		label: 'MyBSS Roam Clearing Manager',
		value: 'MyBSS Roam Clearing Manager'
	},
	{ label: 'MyBSS Script Manager', value: 'MyBSS Script Manager' },
	{ label: 'MyBSS SDB', value: 'MyBSS SDB' },
	{ label: 'MyBSS SLA Manager', value: 'MyBSS SLA Manager' },
	{ label: 'MyBSS Support', value: 'MyBSS Support' },
	{
		label: 'MyBSS Widget (AJ/Recontracting Widget)',
		value: 'MyBSS Widget (AJ/Recontracting Widget)'
	},
	{ label: 'MyQR', value: 'MyQR' },
	{ label: 'NCR Blue Machine (Blue)', value: 'NCR Blue Machine (Blue)' },
	{ label: 'Netcracker Tesbed Network', value: 'Netcracker Tesbed Network' },
	{
		label: 'Netflix Subscription DCB Charging (Matrix Ph2)',
		value: 'Netflix Subscription DCB Charging (Matrix Ph2)'
	},
	{
		label: 'New Frontier (NF/Registration)',
		value: 'New Frontier (NF/Registration)'
	},
	{ label: 'New GlobeOne (SuperApp)', value: 'New GlobeOne (SuperApp)' },
	{ label: 'NIB (Network-In-a-Box)', value: 'NIB (Network-In-a-Box)' },
	{
		label: 'NICE Echo (Fizzback/old VOC)',
		value: 'NICE Echo (Fizzback/old VOC)'
	},
	{ label: 'NICE IEX WFM', value: 'NICE IEX WFM' },
	{
		label: 'NIM (Nice Interaction Management)',
		value: 'NIM (Nice Interaction Management)'
	},
	{
		label: 'Notification Service Bus (NSB)',
		value: 'Notification Service Bus (NSB)'
	},
	{ label: 'NTG NOD/CS Ticketing tool', value: 'NTG NOD/CS Ticketing tool' },
	{ label: 'Observe IT', value: 'Observe IT' },
	{ label: 'OKTA', value: 'OKTA' },
	{
		label: 'One Globe Information Exchange (1GIE)',
		value: 'One Globe Information Exchange (1GIE)'
	},
	{
		label: 'One Repository Server (ORS)',
		value: 'One Repository Server (ORS)'
	},
	{
		label: 'One Touch Service Creation (OTSC)',
		value: 'One Touch Service Creation (OTSC)'
	},
	{ label: 'OneID', value: 'OneID' },
	{
		label: 'Online Charging Adaptor (OCA)',
		value: 'Online Charging Adaptor (OCA)'
	},
	{ label: 'Online Shop (OC3)', value: 'Online Shop (OC3)' },
	{
		label: 'OpenCart Online Shop (Broadway)',
		value: 'OpenCart Online Shop (Broadway)'
	},
	{
		label: 'Oracle Peoplesoft Payroll Management',
		value: 'Oracle Peoplesoft Payroll Management'
	},
	{
		label: 'Oracle Peoplesoft Performance Management',
		value: 'Oracle Peoplesoft Performance Management'
	},
	{ label: 'Oracle Taleo Learn', value: 'Oracle Taleo Learn' },
	{ label: 'Oracle Taleo Recruit', value: 'Oracle Taleo Recruit' },
	{ label: 'Orca', value: 'Orca' },
	{ label: 'Order Management Tool', value: 'Order Management Tool' },
	{ label: 'OSS Inhouse Develop Tools', value: 'OSS Inhouse Develop Tools' },
	{ label: 'OSS Internal Platforms', value: 'OSS Internal Platforms' },
	{
		label: 'Outside Plant Managament System',
		value: 'Outside Plant Managament System'
	},
	{
		label: 'Outsystem Workforce Management (OWFM)',
		value: 'Outsystem Workforce Management (OWFM)'
	},
	{
		label: 'Outsystems Business Process Manager (BPM)',
		value: 'Outsystems Business Process Manager (BPM)'
	},
	{ label: 'Outsystems Corp Collection', value: 'Outsystems Corp Collection' },
	{ label: 'Over The Air (OTA)', value: 'Over The Air (OTA)' },
	{ label: 'Over The Air (OTA) (OLD)', value: 'Over The Air (OTA) (OLD)' },
	{
		label: 'Palo Alto Networks Firewall',
		value: 'Palo Alto Networks Firewall'
	},
	{ label: 'Pay2Globe', value: 'Pay2Globe' },
	{ label: 'Paybill', value: 'Paybill' },
	{ label: 'Paybill Corporate', value: 'Paybill Corporate' },
	{ label: 'Payment Orchestrator', value: 'Payment Orchestrator' },
	{
		label: 'Payment Posting Processing System (Luke)',
		value: 'Payment Posting Processing System (Luke)'
	},
	{ label: 'Paytrack', value: 'Paytrack' },
	{
		label: 'Platinum Relation App (Dalmore)',
		value: 'Platinum Relation App (Dalmore)'
	},
	{ label: 'Portals Platform (Rudy)', value: 'Portals Platform (Rudy)' },
	{
		label: 'Postpaid Promo Provisioning (Voyager)',
		value: 'Postpaid Promo Provisioning (Voyager)'
	},
	{
		label: 'Prepaid Load Seeding System',
		value: 'Prepaid Load Seeding System'
	},
	{
		label: 'Privileged Access Management (PAM)',
		value: 'Privileged Access Management (PAM)'
	},
	{
		label: 'Procurement Disbursement System (DOLLAR)',
		value: 'Procurement Disbursement System (DOLLAR)'
	},
	{
		label: 'Promo Provisioning Platform (P3/Registration)',
		value: 'Promo Provisioning Platform (P3/Registration)'
	},
	{
		label: 'Proof of Identifi cation (3A/POID)',
		value: 'Proof of Identifi cation (3A/POID)'
	},
	{
		label: 'Provisioning System for Broadband (Axioss)',
		value: 'Provisioning System for Broadband (Axioss)'
	},
	{ label: 'Python RPA', value: 'Python RPA' },
	{ label: 'Quest', value: 'Quest' },
	{
		label: 'Queue Management System (QMS)',
		value: 'Queue Management System (QMS)'
	},
	{ label: 'QuickScore', value: 'QuickScore' },
	{ label: 'RAN L7 Firewall', value: 'RAN L7 Firewall' },
	{ label: 'Razor SDP', value: 'Razor SDP' },
	{
		label: 'Real-Time Payment Posting (THEIA)',
		value: 'Real-Time Payment Posting (THEIA)'
	},
	{
		label: 'Reconciliation System (REO)',
		value: 'Reconciliation System (REO)'
	},
	{ label: 'Remedial Management System', value: 'Remedial Management System' },
	{
		label: 'Remedy IT Service Management Ticketing System',
		value: 'Remedy IT Service Management Ticketing System'
	},
	{
		label: 'Request for Condemnation (eRFC Sweepr)',
		value: 'Request for Condemnation (eRFC Sweepr)'
	},
	{ label: 'RevUp (INOPAC)', value: 'RevUp (INOPAC)' },
	{
		label: 'Rewards Dynamic Menu Platform (DMP)',
		value: 'Rewards Dynamic Menu Platform (DMP)'
	},
	{
		label: 'Rewards Management Platform (Millipede)',
		value: 'Rewards Management Platform (Millipede)'
	},
	{ label: 'Ringback Tone (RBT)', value: 'Ringback Tone (RBT)' },
	{ label: 'RMORAS - Microservices', value: 'RMORAS - Microservices' },
	{ label: 'Ruckus SCI', value: 'Ruckus SCI' },
	{
		label: 'Rudy Payment Information System (PIS)',
		value: 'Rudy Payment Information System (PIS)'
	},
	{
		label: 'Sailpoint - Identity and Access Management',
		value: 'Sailpoint - Identity and Access Management'
	},
	{ label: 'Sales Channel Portal (SCP)', value: 'Sales Channel Portal (SCP)' },
	{
		label:
			'Salesforce Communications Cloud (Sales Opportunity Management and Fulfi llment / Vlocity Q2F)',
		value:
			'Salesforce Communications Cloud (Sales Opportunity Management and Fulfi llment / Vlocity Q2F)'
	},
	{
		label: 'Salesforce Community Cloud (Customer Portal Creation)',
		value: 'Salesforce Community Cloud (Customer Portal Creation)'
	},
	{
		label:
			'Salesforce Einstein Analytics (Report Visualization and Forecasting)',
		value:
			'Salesforce Einstein Analytics (Report Visualization and Forecasting)'
	},
	{
		label: 'Salesforce Marketing Cloud (Email Marketing for B2C)',
		value: 'Salesforce Marketing Cloud (Email Marketing for B2C)'
	},
	{
		label: 'Salesforce Pardot (Email Marketing for B2B)',
		value: 'Salesforce Pardot (Email Marketing for B2B)'
	},
	{
		label: 'Salesforce Quip (Document Collaboration)',
		value: 'Salesforce Quip (Document Collaboration)'
	},
	{
		label: 'Salesforce Sales Cloud (Sales Leads and Pipeline Management)',
		value: 'Salesforce Sales Cloud (Sales Leads and Pipeline Management)'
	},
	{
		label: 'Salesforce Service Cloud (Operations Ticketing System)',
		value: 'Salesforce Service Cloud (Operations Ticketing System)'
	},
	{
		label: 'Salesforce Shield (Platform Encryption and Event Monitoring)',
		value: 'Salesforce Shield (Platform Encryption and Event Monitoring)'
	},
	{
		label: 'Sandbox (Test Environment for AWS)',
		value: 'Sandbox (Test Environment for AWS)'
	},
	{ label: 'SAP', value: 'SAP' },
	{ label: 'SAP Ariba', value: 'SAP Ariba' },
	{ label: 'SAP BPC', value: 'SAP BPC' },
	{ label: 'SAP Business Warehouse', value: 'SAP Business Warehouse' },
	{ label: 'SAP Concur', value: 'SAP Concur' },
	{ label: 'SAP Controlling', value: 'SAP Controlling' },
	{ label: 'SAP CRM', value: 'SAP CRM' },
	{ label: 'SAP Dealer Portal', value: 'SAP Dealer Portal' },
	{
		label: 'SAP Disclosure Management (DM)',
		value: 'SAP Disclosure Management (DM)'
	},
	{ label: 'SAP Fieldglass', value: 'SAP Fieldglass' },
	{ label: 'SAP Financial Accounting', value: 'SAP Financial Accounting' },
	{ label: 'SAP Fiori', value: 'SAP Fiori' },
	{
		label: 'SAP Flexible Real Estate Management',
		value: 'SAP Flexible Real Estate Management'
	},
	{
		label: 'SAP Integrated Business Planning',
		value: 'SAP Integrated Business Planning'
	},
	{
		label: 'SAP Investment Management Platform',
		value: 'SAP Investment Management Platform'
	},
	{ label: 'SAP Materials Management', value: 'SAP Materials Management' },
	{ label: 'SAP Plant Maintenance (PM)', value: 'SAP Plant Maintenance (PM)' },
	{ label: 'SAP Process Integration', value: 'SAP Process Integration' },
	{ label: 'SAP Project Systems', value: 'SAP Project Systems' },
	{ label: 'SAP ROAR', value: 'SAP ROAR' },
	{ label: 'SAP S/4HANA', value: 'SAP S/4HANA' },
	{ label: 'SAP Sales and Distribution', value: 'SAP Sales and Distribution' },
	{ label: 'SAP Treasury Management', value: 'SAP Treasury Management' },
	{ label: 'Search (New)', value: 'Search (New)' },
	{ label: 'Service Admin Portal', value: 'Service Admin Portal' },
	{
		label: 'Service Unit Liquidation (SUL)',
		value: 'Service Unit Liquidation (SUL)'
	},
	{ label: 'ServiceNow', value: 'ServiceNow' },
	{ label: 'SG BB App', value: 'SG BB App' },
	{
		label: 'SG Customer Intelligence System',
		value: 'SG Customer Intelligence System'
	},
	{ label: 'SG Portal (BLY)', value: 'SG Portal (BLY)' },
	{ label: 'SG Shop', value: 'SG Shop' },
	{ label: 'Share a Load', value: 'Share a Load' },
	{ label: 'Simple Query', value: 'Simple Query' },
	{ label: 'Sitel Call Recording', value: 'Sitel Call Recording' },
	{ label: 'Slack', value: 'Slack' },
	{ label: 'SMS Broadcaster (Newbie)', value: 'SMS Broadcaster (Newbie)' },
	{
		label: 'SNOW Software Asset Management',
		value: 'SNOW Software Asset Management'
	},
	{
		label:
			'Society for Worldwide Interbank Financial Telecommunications (SWIFT)',
		value:
			'Society for Worldwide Interbank Financial Telecommunications (SWIFT)'
	},
	{
		label: 'Software Engineering Chapter Incubator (POC)',
		value: 'Software Engineering Chapter Incubator (POC)'
	},
	{ label: 'SonarQube', value: 'SonarQube' },
	{ label: 'Spotify (Trance)', value: 'Spotify (Trance)' },
	{ label: 'SSET Toolbox', value: 'SSET Toolbox' },
	{ label: 'SurfAlert Portal (AoC)', value: 'SurfAlert Portal (AoC)' },
	{
		label: 'System for Online Licensing Administration and Storage',
		value: 'System for Online Licensing Administration and Storage'
	},
	{
		label: 'System for Online Licensing Administration and Storage (SOLAS)',
		value: 'System for Online Licensing Administration and Storage (SOLAS)'
	},
	{ label: 'Talend', value: 'Talend' },
	{ label: 'TC RPL API Routing Logic', value: 'TC RPL API Routing Logic' },
	{ label: 'Tenable Nessus Manager', value: 'Tenable Nessus Manager' },
	{
		label: 'Tenable Nessus Network Monitor',
		value: 'Tenable Nessus Network Monitor'
	},
	{ label: 'Tenable Nessus Scanner', value: 'Tenable Nessus Scanner' },
	{
		label: 'Tenable Nessus Security Center',
		value: 'Tenable Nessus Security Center'
	},
	{
		label: 'TGKI Container As A Service (CAAS)',
		value: 'TGKI Container As A Service (CAAS)'
	},
	{ label: 'The Bot Platform', value: 'The Bot Platform' },
	{ label: 'TM App', value: 'TM App' },
	{ label: 'TM Easy Plan (Vanilla)', value: 'TM Easy Plan (Vanilla)' },
	{ label: 'TM Easy Plan V.2 (Vanilla)', value: 'TM Easy Plan V.2 (Vanilla)' },
	{ label: 'Transformer', value: 'Transformer' },
	{
		label: 'Transporter (Sweden File Transfer)',
		value: 'Transporter (Sweden File Transfer)'
	},
	{
		label: 'Trend Micro Workload Security',
		value: 'Trend Micro Workload Security'
	},
	{ label: 'UiPath RPA', value: 'UiPath RPA' },
	{
		label: 'Unified Desktop Agent Assist',
		value: 'Unified Desktop Agent Assist'
	},
	{ label: 'Unified Desktop B2B', value: 'Unified Desktop B2B' },
	{ label: 'Unified User Profi le 2.0', value: 'Unified User Profi le 2.0' },
	{ label: 'uPerform', value: 'uPerform' },
	{ label: 'Vendor Management Tool', value: 'Vendor Management Tool' },
	{
		label: 'Video Content System (Hooq)',
		value: 'Video Content System (Hooq)'
	},
	{ label: 'VMWare VSAN', value: 'VMWare VSAN' },
	{ label: 'Voice of Customer (VOC)', value: 'Voice of Customer (VOC)' },
	{
		label: 'Voting in Absentia For Shareholders (VIASH)',
		value: 'Voting in Absentia For Shareholders (VIASH)'
	},
	{ label: 'Wanda Bot', value: 'Wanda Bot' },
	{ label: 'Wavefront', value: 'Wavefront' },
	{ label: 'Webloading via Globe Shop', value: 'Webloading via Globe Shop' },
	{
		label: 'Wireline Bill View (Transport)',
		value: 'Wireline Bill View (Transport)'
	},
	{ label: 'WorkDay HCM', value: 'WorkDay HCM' },
	{ label: 'Workload Migration', value: 'Workload Migration' },
	{ label: 'Workorder Ticketing Tool', value: 'Workorder Ticketing Tool' },
	{ label: 'Worktools', value: 'Worktools' },
	{ label: 'Zendesk', value: 'Zendesk' },
	{ label: 'Zoloz eKYC', value: 'Zoloz eKYC' },
	{ label: 'Zoom', value: 'Zoom' },
	{ label: 'Grand Total', value: 'Grand Total' },
	{ label: 'Telcoscore', value: 'Telcoscore' },
	{ label: 'GReceipt', value: 'GReceipt' },
	{ label: 'ISDP FS1 Testing', value: 'ISDP FS1 Testing' },
	{ label: 'Middleware Services', value: 'Middleware Services' },
	{ label: 'MS Workforce Management', value: 'MS Workforce Management' },
	{ label: 'Rollout Request Management Tool', value: 'Rollout Request Management Tool' },
	{ label: 'Data Lake', value: 'Data Lake' },
	{ label: 'IAM and SD Automations', value: 'IAM and SD Automations' },
    { label: 'Centralized Controls Platform', value: 'Centralized Controls Platform' },
	{ label: 'TrustDB SIM Card Registration', value: 'TrustDB SIM Card Registration' },
	{ label: 'Data Hub', value: 'Data Hub' },
	{ label: 'AIG Sandbox', value: 'AIG Sandbox' },
	{ label: 'GCP CSSG CDA Decision Maker', value: 'GCP CSSG CDA Decision Maker' }
]

export default PLATFORM_LIST
